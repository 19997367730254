exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insurance-advisor-jsx": () => import("./../../../src/pages/insurance-advisor.jsx" /* webpackChunkName: "component---src-pages-insurance-advisor-jsx" */),
  "component---src-pages-por-fesr-jsx": () => import("./../../../src/pages/por-fesr.jsx" /* webpackChunkName: "component---src-pages-por-fesr-jsx" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-riskapp-platform-js": () => import("./../../../src/pages/riskapp-platform.js" /* webpackChunkName: "component---src-pages-riskapp-platform-js" */)
}

